@import "../../../../../theme";
.container {
  position: relative;
  width: 100%;
  height: 0.5rem;
  background-color: var(--third-black-color);
  margin: 10px 0 0 0;
}
.container_progressBar_view {
  position: absolute;
  height: 0.5rem;
  background-color: var(--second-title-color);
  transition: width 1.4s ease-in-out 0.5s;
}
