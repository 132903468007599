@import "../../../theme";
.container {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  @media (min-width: 1024px) {
    padding-top: 90px;
    margin-top: -90px;
  }
}
.container_contain {
  width: 100%;
  @media (min-width: 1024px) {
    display: flex;
    width: 1276px;
  }
}
.container_bg_primaryHeader {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  left: 0;
  background-color: var(--primary-black-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_bg_primaryContent {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  right: 0;
  background-color: var(--primary-bg-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_bg_secondHeader {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  left: 0;
  background-color: var(--second-black-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_bg_secondContent {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  right: 0;
  background-color: var(--second-bg-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.titleSection {
  display: flex;
  background: var(--primary-black-color);
  padding: 2.45rem 2.45rem 2.45rem;
  @media (min-width: 760px) {
    padding: 3.15rem 3.5rem 3.15rem;
  }
  @media (min-width: 1024px) {
    background: var(--second-black-color);
    width: 30%;
  }
  @media (min-width: 1200px) {
    padding: 4.375rem 5.25rem 1.6625rem;
  }
}
.titleSection_container {
  height: 100%;
  width: 100%;
  text-align: right;
  @media (max-width: 1024px) {
    width: 100%;
    text-align: left;
  }
}
.titleSection_container_title {
  color: var(--primary-title-color);
  font-size: 1.68rem;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.titleSection_container_subtitle {
  color: var(--primary-text-color);
  font-size: 1.4rem;
}
.infoSection {
  width: 100%;
  background-color: var(--primary-bg-color);
  padding: 2.45rem 2.45rem 2.45rem;
  @media (min-width: 760px) {
    padding: 3.15rem 3.5rem 3.15rem;
  }
  @media (min-width: 1024px) {
    width: 70%;
    background-color: var(--second-bg-color);
  }
}
.infoSection_container {
  height: 100%;
  color: var(--primary-text-color);
  font-size: 1.4rem;
  line-height: 1.5;
  @media (min-width: 760px) {
    font-size: 1.7rem;
  }
  @media (min-width: 1024px) {
    position: relative;
  }
  :last-child {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 1.375rem;
  }
}
