@import "../../../theme";
@import url("https://fonts.googleapis.com/css2?family=Yellowtail&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&display=swap");
.container {
  width: 100%;
  height: 100vh;
  background-image: url("../../../image/2025-anniversary/background.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: entranceAnimation 3s ease-out forwards;
}
.content {
  width: 100%;
  .content_minions {
    width: 100%;
    display: flex;
    justify-content: center;
    .content_minions_img {
      margin-top: 35px;
      width: 300px;
    }
  }
  .content_container {
    width: 100%;

    .content_container_title {
      margin-top: 35px;
      font-family: "Yellowtail", cursive;
      font-weight: 400;
      font-style: normal;
      font-size: 60px;
      text-align: center;
      color: #152c77;
    }
    .content_container_border {
      width: 100%;
      display: flex;
      justify-content: center;
      .content_container_border_image {
        margin: 20px 0;
        width: 100px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        animation: shootingStarEntrance 3s ease-out forwards;
      }
    }
    .content_container_text {
      margin-top: 30px;
      font-size: 25px;
      text-align: center;
      color: #152c77;
      font-family: "Chocolate Classical Sans", sans-serif;
    }
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shootingStarEntrance {
  0% {
    opacity: 0;
    transform: translateX(-300px) translateY(-500px) scale(0.5) rotate(-45deg);
  }
  50% {
    opacity: 1;
    transform: translateX(0) translateY(0) scale(1.2) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0) scale(1);
  }
}

@keyframes entranceAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100px) scale(0.8) rotate(-10deg);
  }
  50% {
    opacity: 0.5;
    transform: translateY(20px) scale(1.1) rotate(5deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1) rotate(0deg);
  }
}
