@import "../../../theme";
.container {
  z-index: 1;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  @media (min-width: 1024px) {
    height: 100vh;
  }
}
.container_bg_primaryHeader {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  left: 0;
  background-color: var(--primary-black-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_bg_primaryContent {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  right: 0;
  background-color: var(--primary-bg-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_bg_secondHeader {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  left: 0;
  background-color: var(--second-black-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_bg_secondContent {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  right: 0;
  background-color: var(--second-bg-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_contain {
  width: 100%;
  background-color: var(--primary-bg-color);
  @media (min-width: 1024px) {
    display: flex;
    width: 1276px;
  }
}
.iconSection {
  display: flex;
  padding: 6.75rem 2.45rem 0rem;
  @media (min-width: 760px) {
    padding: 3.15rem 3.5rem 1.4rem;
  }
  @media (min-width: 1024px) {
    background: var(--primary-black-color);
    width: 30%;
  }
  @media (min-width: 1200px) {
    padding: 4.375rem 5.25rem 1.6625rem;
  }
  justify-content: flex-end;
  align-items: center;
}
.iconSection_container {
  height: 100%;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.iconSection_container_avatar {
}
.iconSection_container_avatar_img {
  border-radius: 50%;
  width: 15rem;
  @media (min-width: 760px) {
    width: 19rem;
  }
  @media (min-width: 1200px) {
    width: 25rem;
  }
}
.infoSection {
  width: 100%;
  padding: 1.75rem 2.45rem 0rem;
  @media (min-width: 760px) {
    padding: 3.15rem 3.5rem 1.4rem;
  }
  @media (min-width: 1024px) {
    width: 70%;
  }
}
.infoSection_container {
  height: 100%;
  align-items: center;
  @media (min-width: 1024px) {
    display: flex;
    position: relative;
  }
}
.infoSection_container_header {
  width: 100%;
  text-align: center;
  margin: 0 0 35px 0;
  padding: 0 1.75rem 0;
  @media (min-width: 1024px) {
    text-align: left;
    margin: 0;
    padding: 0;
  }
}
.iconSection_container_EngName {
  font-size: 5rem;
  font-weight: 100;
  color: var(--primary-text-color);
  @media (max-width: 1200px) {
    font-size: 4rem;
  }
  @media (max-width: 760px) {
    font-size: 3rem;
  }
}
.iconSection_container_ChiName {
  font-size: 5rem;
  font-weight: 600;
  color: var(--second-title-color);
  @media (max-width: 1200px) {
    font-size: 4rem;
  }
  @media (max-width: 1024px) {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 760px) {
    font-size: 3rem;
  }
}
.iconSection_container_subTitle {
  font-size: 2rem;
  color: var(--primary-text-color);
  padding-top: 10px;
  @media (max-width: 1200px) {
    font-size: 1.6rem;
  }
  @media (max-width: 760px) {
    font-size: 1.4rem;
  }
}
.infoSection_container_footer {
  width: 100%;
  @media (min-width: 1024px) {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
  }
}
.infoSection_container_footer_line {
  color: var(--third-black-color);
  height: 2px;
  margin: 0 0 20px 0;
}
.infoSection_container_footer_contact {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
